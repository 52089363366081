/* CollectionsComponent.css */
.page-margin {
  margin-top: 200px; /* Ajusta el valor según la altura de tu barra de navegación */
}

.title-container {
  text-align: center;
  margin-bottom: 30px;
margin-top: 230px;
 
}

.collection-title {
  font-size: 28px;
  color: #ffffff;
  
}

.collectionContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
 /* background-color: #100f0ff8;*/
 margin-bottom: 50px;
}

.collectionItem {
  margin: 20px;
}

.collectionImage {
  overflow: hidden; /* Para asegurarse de que no haya desbordamiento del contenido */
  background-color: #100f0ff8; /* Color de fondo para el espacio en blanco alrededor de la imagen */
  cursor: pointer; /* Cambiar el cursor a un puntero al pasar el ratón sobre la imagen */

}

.collectionImage img {
  display: block;
  width: 100%;
  max-width: 350px;
  height: auto;
 /* border: 7px solid  #100f0ff8;*/
  box-sizing: border-box;
}

.commentary {
  color: white;
  font-size: 15px;
  font-family: 'Didot', serif; /* Establece la fuente "Didot" para los comentarios */
  font-style: italic;
  width: 80%;
  text-align: left;
  margin-top: 8px;
  background-color: #100f0ff8 !important;
}

.zoomedImage {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  max-width: 90%; /* Ajusta el tamaño máximo de la imagen ampliada según sea necesario */
  max-height: 90vh; /* Ajusta la altura máxima de la imagen ampliada según sea necesario */
  background-color: rgba(0, 0, 0, 0.8); /* Fondo oscuro con transparencia */
  padding: 20px;
  border-radius: 10px;
}

.zoomedImage img {
  width: 100%;
  height: auto;
  display: block;
  cursor: zoom-out; /* Cambiar el cursor a una lupa al pasar el ratón sobre la imagen ampliada */

}

/* Estilos para el fondo oscurecido */
.darkOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Fondo oscuro con transparencia */
  z-index: 9998;
}


/* Estilos para las barras de desplazamiento */
.zoomedImage::-webkit-scrollbar {
  width: 10px; /* Ancho de las barras de desplazamiento */
}

.zoomedImage::-webkit-scrollbar-thumb {
  background-color: #ffffff; /* Color del pulgar de la barra de desplazamiento */
  border-radius: 5px; /* Borde redondeado del pulgar */
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  z-index: 10000;
}

.closeButton::before, .closeButton::after {
  content: '';
  position: absolute;
  width: 2px;
  height: 24px;
  background-color: white;
  top: 3px;
  left: 14px;
}

.closeButton::before {
  transform: rotate(45deg);
}

.closeButton::after {
  transform: rotate(-45deg);
}
