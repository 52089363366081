/* ArtistaPage.css */
.artista-margin {
    margin-top: 200px; /* Ajusta el valor según la altura de tu barra de navegación */
  }
  
  .inicio-page {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding-top: 70px;
    background-color: #100f0ff8;
  }
  
  .imagen-instagram {
    width: 100px; /* Ajusta el ancho según sea necesario */
    height: auto; /* Esto mantiene la proporción original */
    text-decoration: none; /* Quita el subrayado del enlace */
  }
  
  .imagen-instagram p {
    margin: 0; /* Ajusta el margen del párrafo si es necesario */
    text-decoration: none;
  }
  
  .contenedor-imagenes-artisticas a p {
    text-decoration: none; /* También quita el subrayado del párrafo dentro del enlace */
    margin: 0; /* Ajusta el margen del párrafo si es necesario */
    /* Otros estilos de fuente según sea necesario */
  }
  .contenedor-imagenes-artisticas {
    background-color: #100f0ff8;
    margin: 20px;
    padding: 15px;
    text-align: center;
  }
  
  .contenedor-imagenes-artisticas a {
    text-decoration: none; /* Quita el subrayado del enlace */
    color: inherit; /* El color del texto será el mismo que el color del texto de su contenedor */
  }
  
  
  
  .titulo-principal {
   /* font-family: 'Lobster', cursive;*/
    font-size: 96px;
    color: #ffffffe4;
    margin-bottom: 20px;
    font-weight: lighter; /* Hace la letra más fina */
    font-weight: 300;
    letter-spacing: 1px; /* Ajusta el espaciado de letras según sea necesario */
  }
  
  /* Ajustes para dispositivos móviles */
  @media (max-width: 767px) {
    .titulo-principal {
      font-size: 36px; /* Ajusta el tamaño del texto para dispositivos móviles según sea necesario */
    }
  }
  
  .imagenes-artisticas {
    display: flex;
    flex-wrap: wrap; /* Asegura que las imágenes se envuelvan en dispositivos pequeños */
    justify-content: center;
    gap: 10px;
  }
  
  .imagenes-artisticas img {
    width: 150px;
    height: 250px;
    object-fit: cover;
    /*border-radius: 10px;*/
    transition: transform 0.3s ease;
  }
  
  .imagenes-artisticas img:hover {
    transform: scale(1.05);
  }
  
  /* Ajusta el tamaño de las imágenes solo para dispositivos móviles */
  @media (max-width: 200px) {
    .imagenes-artisticas img {
      width: 100%; /* Haz que las imágenes ocupen el ancho completo del contenedor en dispositivos móviles */
      max-width: none; /* Elimina el máximo de ancho para que la imagen se expanda completamente */
      height: auto;
      
      
    }
  }
  
  
  
  
  .texto-entre-imagenes {
    text-align: center;
    margin: 50px;
    font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
    font-size: 20px;
    line-height: 1.5;
    color: #ffffff;
    font-weight: lighter; /* Hace la letra más fina */
  }
  
  .contenedor-casemiro {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    margin: 20px auto;
    padding: 20px;
    max-width: 800px;
    background-color: #000;
    font-weight: lighter; /* Hace la letra más fina */
  }
  
  .imagen-casemiro img {
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .imagen-casemiro:hover img {
    transform: scale(1.05);
  }
  
  .video-overlay {
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    margin-top: 35px;
 
    object-fit: cover;
  }
  
  .texto-estilo-artistico {
    text-align: center;
    margin: 30px 0;
  }
  
  .artistic-text {
    font-family: 'Museo'; /* Cambia a una fuente elegante */
    font-size: 36px; /* Tamaño del texto */
    color: #fff; /* Color blanco */
    font-weight: 100; /* Hace que la letra sea más ligera/fina */
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  .imagen-con-titulo {
    position: relative;
    overflow: hidden;
  }
  
  .titulo-imagen {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    
    color: #fff;
    background-color: rgba(0, 0, 0, 0.7); /* Fondo oscuro semitransparente */
    padding: 10px; /* Ajusta el relleno según tus preferencias */
    border-radius: 5px; /* Agrega bordes redondeados */
    transition: background-color 0.3s ease; /* Transición del fondo al pasar el mouse */
  }
  
  .titulo-imagen:hover {
    background-color: rgba(0, 0, 0, 0.9); /* Cambia el fondo al pasar el mouse */
  }
  
  /* ArtistaPage.css */
  
  .div-click {
    text-align: center;
    margin-top: 20px;
    padding: 10px;
    font-size: 14px;
    color: #fff;
    background-color: #272424; /* Fondo oscuro */
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Transición del fondo al pasar el mouse */
  }
  
  .div-click:hover {
    background-color: #ff006a; /* Cambia el fondo al pasar el mouse */
  }
  
  