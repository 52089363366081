/* CapituloCas1.css */
.imagenes-adicionales3 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;  /* Esto permite que las imágenes se envuelvan a la siguiente línea */
    margin-top: 190px; /* Ajusta el margen superior según sea necesario */
  }
  
  .imagenes-adicionales3 img {
    width: 450px;  /* Ajusta el ancho según sea necesario */
    height: auto;  /* Ajusta la altura automáticamente para mantener la proporción */
    margin: 10px;  /* Ajusta el margen entre las imágenes según sea necesario */
  }
  