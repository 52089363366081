/* FooterComponent.css */
.footer {
    background-color: #333;
    color: #fff;
    padding: 20px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .footer .container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .footer p {
    margin: 10px 0;
  }
  
  .footer a {
    color: #00aaff;
    text-decoration: none;
  }
  
  .social-icons {
    font-size: 24px;
    display: flex;
    justify-content: center;
  }
  
  .social-icons a {
    color: #fff;
    text-decoration: none;
    margin: 0 10px;
    display: flex;
    align-items: center;
  }
  
  .instagram-icon {
    margin-right: 5px;
  }
  
  .instagram-text {
    font-size: 16px;
    color: #fff;
    vertical-align: middle;
  }
  