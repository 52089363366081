
/* Estilos para la barra de navegación */
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    background-color: rgba(14, 14, 14, 0.9);
    padding: 1rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    justify-content: center;
    flex-direction: column;
    text-align: center;
    z-index: 9999;
    transition: transform 0.3s;
    transform: translateY(0);
    height: 150px;
  }
  
  .navbar.hidden {
    transform: translateY(-100%);
  }
  
  /* Estilos para el logotipo de texto "GIOP" */
  .logo {
    font-size: 24px;
    margin-top: 0px;
  }
  
  /* Estilos para los enlaces de navegación */
  .nav-links {
    display: flex;
    font-weight: lighter;
    margin-bottom: 15px;
  }
  
  .nav-link {
    text-decoration: none;
    color: #fff;
    margin: 0 10px;
    transition: color 0.3s, text-shadow 0.3s; /* Agregamos transición para el color y la sombra de texto */
  }
  
  .nav-link:hover {
    color: #fff;
    text-decoration: none;
    text-shadow: 0 0 20px rgba(255, 255, 255, 1); /* Aumenta el radio de desenfoque y la opacidad */
  }
  .line {
    border-top: 1px solid #fff;
    width: 100%;
    margin: 10px 0;
  }
  
  .active-link {
    color: #ffcc00;
    text-decoration: none;
    font-weight: bold;
  }
  
  .nav-link:focus {
    outline: none;
    background-color: rgba(255, 255, 255, 0.09);
    transition: background-color 0.3s;
  }
  
  .nav-link:active {
    background-color: rgba(255, 255, 255, 0.4);
    transition: background-color 0.1s;
  }


  .firma {
    width: 20vw; /* Ancho de la imagen como el 20% del ancho de la ventana gráfica */
    display: block; /* Asegura que la imagen esté centrada */
    margin: 0 auto; /* Centra horizontalmente la imagen */

    /* Tamaño mínimo para dispositivos móviles */
    @media only screen and (max-width: 768px) {
        width: 80%; /* Porcentaje del ancho de la ventana gráfica */
    }
}